import domReady from '@wordpress/dom-ready';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { _x } from '@wordpress/i18n';

domReady(() => {
  Fancybox.defaults.l10n = {
    CLOSE: _x('Close', 'fancybox label', 'vtx'),
    MODAL: _x(
      'You can close this modal content with the ESC key',
      'fancybox label',
      'vtx'
    ),
  };

  Fancybox.bind('[data-fancybox]', {
    autoFocus: false,
    on: {
      close: () => {
        const actionsButtons = document.querySelectorAll(
          '.service-actions__item'
        );
        if (actionsButtons.length) {
          actionsButtons.forEach((item) => {
            item.blur();
          });
        }
      },
    },
  });

  const fancyClose = () => {
    const fancyCloseBtn = document.querySelectorAll(
      '.service-actions__fancybox-close'
    );
    if (fancyCloseBtn.length) {
      fancyCloseBtn.forEach((item) => {
        item.addEventListener('click', function () {
          Fancybox.close();
        });
      });
    }
  };

  const fancyGravityFormBtn = document.querySelector(
    '.places-listing__accordions--active'
  );

  if (fancyGravityFormBtn) {
    fancyGravityFormBtn.addEventListener('click', function (e) {
      const target = e.target;
      if (target.hasAttribute('data-gf-id')) {
        const gfId = target.getAttribute('data-gf-id');
        const ajaxParams = window.ajaxParams || {};
        const gfFormContainer =
          target.nextElementSibling.querySelector('.gf-form-container');
        const iframeUrl = `${ajaxParams.formBaseUrl}?id=${gfId}&nonce=${ajaxParams.nonce}`;
        gfFormContainer.innerHTML = `<iframe class="places-listing__gf-iframe" src="${iframeUrl}" frameborder="0" allowfullscreen></iframe>`;
      }
    });
  }

  document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape') {
      Fancybox.close();
    }
  });

  fancyClose();
});
